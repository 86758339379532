<template>
    <section>
        <a-form class="form-container" :form="form">
            <a-row type="flex" :gutter="20">
                <a-col :span="8">
                    <a-form-item label="问题部位" >
                        <a-input placeholder="请输入" v-decorator="['position',{rules:[{required:true,message:'请输入问题部位'},{max:200,message:'最大输入200个字符'}]}]"></a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="问题描述" >
                        <a-input placeholder="请输入" v-decorator="['description',{rules:[{required:true,message:'请输入问题描述'},{max:200,message:'最大输入200个字符'}]}]"></a-input>
                    </a-form-item>
                </a-col>
                 <a-col :span="8">
                    <a-form-item label="问题等级 " >
                        <a-select placeholder="请选择" v-decorator="['rank',{rules:[{required:true,message:'请选择问题等级'}]}]">
                            <a-select-option v-for="(item,key) in rankMap" :key="key" :value="item.id">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row  type="flex" :gutter="20">
               
                <a-col :span="8">
                    <a-form-item label="检查人 " >
                        <a-input :value="checkPersonName" disabled></a-input>
                        <!-- <select-user-cascader @func="getCheckPerson" v-decorator="['checkPerson',{rules:[{required:true,message:'请选择检查人'}]}]" /> -->
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="检查时间" >
                        <a-date-picker :show-time="{ format:'HH:mm' }" format="YYYY-MM-DD HH:mm" v-decorator="['checkTime',{rules:[{required:true,message:'请选择检查时间'}]}]"/>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="责任单位" >
                        <a-select @change="e=> changeCompany(e)" v-decorator="['responsibilityCompany',{rules:[{required:true,message:'请选择责任单位'}]}]">
                            <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="20">
                 <a-col :span="8">
                    <a-form-item label="整改人 " >
                        <a-select placeholder="请选择" v-decorator="['rectifyPerson',{rules:[{required:true,message:'请选择整改人'}]}]">
                            <a-select-option v-for="item in userLists" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                        </a-select>
                        <!-- <select-user-cascader ref="rectifyPerson"  @func="getRectifyPerson" v-decorator="['rectifyPerson',{rules:[{required:true,message:'请选择整改人'}]}]" /> -->
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="整改时限" >
                        <a-date-picker v-decorator="['rectifyDuration',{rules:[{required:true,message:'请选择整改时限'}]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="20">
                 <a-col :span="8">
                    <a-form-item label="通知人 " >
                        <a-tree-select
                            style="width: 100%"
                            :dropdownStyle="{height:'400px'}"
                            :tree-data="userTreeData"
                            tree-checkable
                            treeCheckStrictly
                            search-placeholder="请选择"
                            v-decorator="['notiPerson',{rules:[{required:false,message:'请选择通知人'}]}]" 
                        />
                        <!-- <select-user-cascader @func="getNotiPerson" v-decorator="['notiPerson',{rules:[{required:true,message:'请选择通知人'}]}]" /> -->
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="复核人" >
                        <select-user-cascader ref="recheckPerson" @func="getRecheckUser" v-decorator="['recheckPerson',{rules:[{required:true,message:'请选择复核人'}]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="16">
                    <a-form-item label="相关照片" >
                        <a-row type="flex">
                            <a-row class="upload-preview-wrap">
                                <a v-for="(item,key) in fileLists" :key="key" class="file-item m-r-10">
                                    <!-- <a-avatar  shape="square" :size="100" :src="item.url" /> -->
                                    <preview-image :data="item" :size="100" />
                                    <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                                </a>
                            </a-row>
                            <upload 
                            v-decorator="['photo',{ rules: [{ required: true, message: '请上传图片' }]}]"
                            style="display:inline-block;width:100px" 
                            @func="getFile" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']"/>
                        </a-row>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="16">
                    <a-form-item label="补充说明" >
                        <a-textarea rows="4" v-decorator="['extra',{rules:[{required:false,message:'请输入补充说明'},{max:200,message:'最大输入200个字符'}]}]"></a-textarea>
                    </a-form-item>
                </a-col>
            </a-row>
           <a-row>
                <a-col :span="16">
                    <a-form-item label="整改要求" >
                        <a-textarea rows="4" v-decorator="['rectifyStandard',{rules:[{required:false,message:'请输入整改要求'},{max:200,message:'最大输入200个字符'}]}]"></a-textarea>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row class="form-btn-group">
                <a-col :span="16">
                    <a-button @click="submit" :disabled="submitLoadding" :loading="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                    <a-button @click="$router.back()">取消</a-button>
                </a-col>
            </a-row>
        </a-form>
    </section>
</template>

<script>
import moment from 'moment'
import upload from '@/components/upload'
import previewImage from '@/components/previewImage'
import selectUserCascader from '@/components/select-user-cascader'
export default {
    data(){
        return{
            rankMap:[],
            fileLists:[],
            companyMap:[],
            companyId:'',
            userLists:[],
            userTreeData:[],
            checkPerson:'',
            checkPersonName:''
        }
    },
    components:{upload,selectUserCascader,previewImage},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created() {
        this.querySafeLevelList()
        this.queryCompanyDropDownBox()
         this.queryUserDropDownBox()
        this.queryUserOptionsDialog()
        if(!this.rid){
            
            this.checkPerson = this.userId
            this.checkPersonName = this.userNickname
            this.$nextTick(() =>{
                this.$refs.recheckPerson.getSelectKey(this.userId)
                this.form.setFieldsValue({
                    recheckPerson:this.userId
                })
            })
        }
        //检查时间默认为当前时间
        this.$nextTick(() =>{
            this.form.setFieldsValue({
                checkTime:moment()
            })
        })
    },
    mounted(){
       
                   
    },
    computed:{
        rid(){
            return this.$route.query.id
        },
        userId(){
            return this.$store.state.userInfo.userId
        },
        userNickname(){
            return this.$store.state.userInfo.userNickname
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        querySafeLevelList(){
            this.$api.querySafeLevelList().then(res =>{
                if(res.code === 200){
                    this.rankMap = (res.data && res.data.records) || []
                }
            })
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
         changeCompany(comId){
            this.companyId = comId
            this.queryUserDropDownBox()
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    rectifyPerson:''
                })
            })
        },
        //用户下拉接口
        queryUserDropDownBox(){
            let params = {}
            if(this.companyId){
                params.companyId = this.companyId
            }
            
            this.$api.queryUserDropDownBox(params).then(res =>{
                if(res.code === 200){
                    this.userLists = res.data || []
                }
            })
        },
        //用户树下拉接口
        queryUserOptionsDialog(){
            this.$api.queryUserOptionsDialog().then(res =>{
                if(res.code === 200){
                   
                    let data = res.data || []
                    data.forEach(item =>{
                        item.title = item.companyName
                        item.value = item.id
                        item.key = item.id
                        item.checkable = false
                        item.children = item.user ? item.user : []
                        if(item.children.length){
                            item.children.forEach(v =>{
                                v.title = v.name
                                v.value = v.id
                                v.key = v.id
                            })
                        }
                    })
                    this.userTreeData = data
                    this.rid && this.querySafeRecordDetails()
                }
            })
        },
        //选择复核人
        getRecheckUser(userId){
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    recheckPerson:userId
                })
            })
        },
        querySafeRecordDetails(){
            this.$api.querySafeRecordDetails(this.rid).then(res =>{
                if(res.code === 200){
                    let info = res.data || {}
                    let formData = {
                        checkTime:info.checkTime ? moment(info.checkTime) : null,
                        description:info.description,
                        extra:info.extra,
                        position:info.position,
                        rank:info.rank,
                        rectifyStandard:info.rectifyStandard,
                        responsibilityCompany:info.responsibilityCompany,
                        recheckPerson:info.recheckPerson,
                        rectifyPerson:info.rectifyPerson,
                        rectifyDuration:info.rectifyDuration ? moment(info.rectifyDuration) : null,
                    }


                    let notiPerson = []
                    this.userTreeData.forEach(item =>{
                        if(item.children && item.children.length){
                            item.children.forEach(v =>{
                                if(info.notiPerson && info.notiPerson.includes(v.id)){
                                    notiPerson.push({label:v.name,value:v.id})
                                }
                            })
                        }
                    })
                    formData.notiPerson = notiPerson
                   
                    this.checkPersonName = info.checkPerson
                    this.checkPerson = this.checkPersonId


                    this.$nextTick(() =>{
                        this.$refs.recheckPerson.getSelectKey(info.recheckPerson)
                        // this.$refs.rectifyPerson.getSelectKey(info.rectifyPerson)
                    })
                    try {
                        //this.fileLists = info.photo ? JSON.parse(info.photo) : []
                        this.fileLists = info.photo ? info.photo.split(',') : []

                    } catch (error) {
                        this.fileLists = []
                    }
                    formData.photo = this.fileLists.length ? this.fileLists.join(',') : ''
                    this.form.setFieldsValue(formData)
                }
            })
        },
        getFile(file){
            this.fileLists.push(file.codeUrl)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
        },
        deleteFile(list){
            this.fileLists = this.fileLists.filter(item => item != list)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    photo:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
        },
        //新增和编辑提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.rid){
                        formData.id = this.rid
                    }
                    //formData.photo = this.fileLists.length ? this.fileLists.join(',') : ''
                    formData.checkTime = formData.checkTime.format('YYYY-MM-DD HH:mm')
                    formData.rectifyDuration = formData.rectifyDuration.format('YYYY-MM-DD')
                    formData.checkPerson = this.checkPerson
                    formData.notiPerson = formData.notiPerson ?  formData.notiPerson.map(item => item.value).join(',') : ''
                    
                    let apiName = this.rid ? 'updateSafeRecord' : 'insertSafeRecord'

                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>