<template>
    <a-cascader v-model="selected" placeholder="请选择" :display-render="displayRender" :options="userTreeData" @change="selectUser" />
</template>

<script>
    export default {
        data() {
            return {
                userTreeData: [],
                selected:[]
            }
        },
        created(){
            this.queryUserOptionsDialog()
        },
        methods: {
            displayRender({
                labels
            }) {
                return labels[labels.length - 1];
            },
            queryUserOptionsDialog() {
                this.$api.queryUserOptionsDialog().then(res => {
                    if (res.code === 200) {
                        let data = res.data || []
                        this.userTreeData = this.formatData(data)
                    }
                })
            },
            formatData(arr) {
                arr.forEach(item => {
                    item.label = item.companyName
                    item.value = item.id
                    item.children = item.user || []
                    item.disabled = (item.user && item.user.length) ? false : true
                    if (item.children && item.children.length) {
                        item.children.forEach(v => {
                            v.label = v.name
                            v.value = v.id
                        })
                    }
                })
                return arr
            },
            getSelectKey(id){
                
                setTimeout(() => {
                    this.userTreeData.forEach(item =>{
                        if(item.children && item.children.length){
                            item.children.forEach(v =>{
                                if(v.id === id){
                                    this.selected = [item.id,v.id]
                                }
                            })
                        }
                    }) 
                }, 500);
                
            },
            selectUser(v){
                let userId = v.length ? v[v.length-1] : ''
                this.$emit('func',userId)
            }
        }
    }
</script>